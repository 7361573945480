import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import { MaterialIcon } from '../materialIcon';

/**
 * Renders a payment selection button with specified gateway, title, and description.
 *
 * @param {'cash' | 'card'} gateway - The payment gateway type.
 * @param {string} title - The title of the payment selection.
 * @param {string} [description] - The optional description of the payment selection.
 * @param {ButtonProps} buttonProps - Additional props for the Button component.
 * @return {JSX.Element} The rendered payment selection button.
 */
export function PaymentSelection({
  gateway,
  title,
  description,
  ...buttonProps
}: {
  gateway: 'cash' | 'card';
  title: string;
  description?: string;
} & ButtonProps) {
  return (
    <Button
      type="submit"
      data-testid={`gateway-${gateway}`}
      sx={{
        gap: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: 'auto',
        textAlign: 'left',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: 4,
        padding: 2,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: '#F3ECFF',
        },
        '&:focus': {
          backgroundColor: '#F3ECFF',
        },
        transition: '0.2s ease-in-out',
      }}
      {...buttonProps}
    >
      <Box>
        {gateway === 'cash' && <MaterialIcon icon="payments" color="primary" containerType="circle" />}
        {gateway === 'card' && <MaterialIcon icon="credit_card" color="primary" containerType="circle" />}
      </Box>
      <Stack direction="column" alignItems="flex-start" gap={1}>
        <Typography variant="h6" color="secondary.main">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </Button>
  );
}
