import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MUICheckbox,
  type CheckboxProps,
  type TextFieldProps,
} from '@mui/material';

/**
 * Renders a custom Checkbox component from a FormControlLabel.
 *
 * @param {string} label - The label text for the checkbox.
 * @param {boolean} required - Boolean that makes the checkbox required.
 * @param {string} helperError - The helper text to show when an error exists.
 * @return {JSX.Element} The rendered custom checkbox component.
 */
export const Checkbox = ({
  label,
  required,
  error,
  helperText,
  ...rest
}: CheckboxProps & {
  label: string;
  required?: boolean;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
}) => {
  return (
    <FormControl>
      <FormControlLabel
        data-testid="checkbox"
        variant={error ? 'error' : 'checkbox'}
        sx={{
          alignItems: 'center',
          gap: 1,
          pr: 1,
          margin: '0px',
        }}
        control={<MUICheckbox data-testid={`checkbox-${label}`} {...rest} />}
        required={required}
        label={label}
      />
      <FormHelperText sx={{ margin: '3px 14px 0px' }} error={error}>
        {required && error ? helperText : ''}
      </FormHelperText>
    </FormControl>
  );
};
