'use client';
import {
  Badge,
  Button,
  Collapse,
  Divider,
  IconButton,
  Table as MUITable,
  Pagination,
  Stack,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Doodles } from '../../assets';
import { COLORS } from '../../constants';
import { theme } from '../../theme';
import { MaterialIcon, type MaterialIconType } from '../materialIcon';
import { filterField, Filters } from './filter';

export type CellType = {
  label: string;
  showLabelMobile?: boolean;
  align?: TableCellProps['align'];
};

export type TableHeadersType = CellType[];

export type RowDataType = {
  name?: string;
  rawValue?: string;
  value: string | number | JSX.Element;
  align?: TableCellProps['align'];
};

export type TableDataType = {
  rowData: RowDataType[];
  rowRedirect?: string;
  rowAction?: () => void;
  dropdownContent?: JSX.Element;
};

export type TableActions = {
  onClick: () => void;
  label: string;
  icon: MaterialIconType;
  active?: boolean;
  badge?: number;
};

export type TableProps = {
  title?: string;
  subtitle?: string;
  actions?: TableActions[];
  headers: TableHeadersType;
  showHeadersMobile?: boolean;
  filters?: filterField[];
  tableData: TableDataType[];
  rows: number;
  flex?: boolean;
  dropdown?: boolean;
  clickableRows?: boolean;
  hideActions?: boolean;
  hidePagination?: boolean;
  downloadEndpoint?: string;
};

/**
 * A component that renders a table with filters and pagination.
 *
 * The table will render a set of headers, and a set of rows. Each row will
 * contain a set of cells, with the value of each cell being the result of
 * `rowData[index].value`. If the value is an object, the object will be
 * rendered directly in the cell.
 *
 * The component will also render a set of filters above the table, and a
 * pagination component below the table. The filters will be rendered as a
 * set of text fields, with the label of each filter being the value of the
 * `label` property of the corresponding `filterField` object.
 *
 * The pagination component will render a set of buttons that allow the user
 * to navigate through the table. The buttons will be labeled with the page
 * number that they correspond to.
 *
 * The table will be rendered with a flexbox layout, with the headers and rows
 * being rendered as flex items. The flex items will be arranged in a row, with
 * the headers being rendered above the rows.
 *
 * The table will be rendered with a set of styles that make it look like a
 * material-ui table.
 *
 * @prop {string} title - The title of the table.
 *
 * @prop {string} subtitle - The subtitle of the table.
 *
 * @prop {filterField[]} filters - An array of objects that define the filters
 * that will be rendered above the table.
 *
 * * `[date_from]` and `[date_to]` are used to filter date ranges
 *
 * * `|` will be interpreted as OR
 *
 * @prop {CellType[]} headers - An array of objects that define the headers of
 * the table.
 *
 * @prop {boolean} showHeadersMobile - A boolean that indicates whether the headers should be shown on mobile.
 *
 * @prop {TableDataType[]} tableData - An array of objects that define the
 * data that will be rendered in the table.
 *
 * @prop {number} rows - The number of rows that will be rendered in the table.
 *
 * @prop {boolean} flex - A boolean that indicates whether the rows variant
 * should be set to mobileFlex instead of the base table-row display.
 *
 * @prop {boolean} dropdown - A boolean that indicates whether the rows
 * should be a dropdown.
 *
 * @prop {boolean} clickableRows - A boolean that indicates whether the rows
 * should be clickable.
 *
 * @prop {boolean} hideActions - A boolean that indicates whether the
 * actions should be hidden.
 *
 * @prop {boolean} hidePagination - A boolean that indicates whether the
 * pagination component should be hidden.
 *
 * @prop {string} downloadEndpoint - Endpoint to download table CSV.
 *
 * @returns {JSX.Element} - The rendered table component.
 */
export const Table = ({
  title,
  subtitle,
  actions,
  filters,
  headers,
  showHeadersMobile,
  tableData,
  rows,
  flex,
  dropdown,
  clickableRows,
  hideActions,
  hidePagination,
  downloadEndpoint,
}: TableProps) => {
  const router = useRouter();
  const [tablePage, setTablePage] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  useEffect(() => {
    setShowFilters(typeof window !== 'undefined' && window.innerWidth >= theme.breakpoints.values.md);
  }, []);

  const searchParams = useSearchParams();

  let filteredData = tableData;
  const allActions: TableActions[] = [...(actions || [])];

  if (downloadEndpoint) {
    allActions.push({
      onClick: () => {
        alert('download report');
      },
      label: 'Descargar lista',
      icon: 'download',
    });
  }

  if (filters?.length) {
    allActions.push({
      onClick: () => {
        setShowFilters(!showFilters);
      },
      active: showFilters,
      label: 'Filtros',
      icon: 'filter_list',
      badge: searchParams.size,
    });
  }

  if (searchParams) {
    searchParams.forEach((param, key) => {
      if (key.includes('[date_from]')) {
        filteredData = filteredData.filter(({ rowData }) =>
          rowData.some(
            ({ name, rawValue }) =>
              name === key.replace('[date_from]', '') && new Date(`${rawValue}`) >= new Date(`${param} 00:00:00`),
          ),
        );
      } else if (key.includes('[date_to]')) {
        filteredData = filteredData.filter(({ rowData }) =>
          rowData.some(
            ({ rawValue, name }) =>
              name === key.replace('[date_to]', '') && new Date(`${rawValue}`) <= new Date(`${param} 23:59:59`),
          ),
        );
      } else {
        filteredData = filteredData.filter(({ rowData }) =>
          rowData.some(({ rawValue, name, value }) => {
            const validValue = `${rawValue || value}`;
            return (
              name === key &&
              param?.split('|').some((_value) => validValue.toLowerCase().includes(_value.toLowerCase()))
            );
          }),
        );
      }
    });
  }

  useEffect(() => {
    if (filteredData?.length <= rows) {
      setTablePage(0);
    }
  }, [filteredData]);

  return (
    <Grid container wrap="nowrap" sx={{ width: '100%', flexDirection: 'column', gap: 2 }}>
      <Grid
        container
        sx={{
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
          px: { xs: 2, md: 0 },
          pt: { xs: 2, md: 0 },
        }}
      >
        {title && <Typography variant="h1">{title}</Typography>}
        <Grid
          container
          sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', display: hideActions ? 'none' : 'flex' }}
        >
          {allActions?.map(({ onClick, label, icon, active, badge }) => (
            <Tooltip key={label} title={label}>
              <IconButton onClick={onClick} style={{ padding: 0 }}>
                <Badge color="error" badgeContent={badge} invisible={!badge}>
                  <MaterialIcon icon={icon} sx={{ color: active ? COLORS.primary.purple.main : COLORS.text.light3 }} />
                </Badge>
              </IconButton>
            </Tooltip>
          ))}
        </Grid>
      </Grid>
      {subtitle && (
        <Typography sx={{ px: { xs: 2, md: 0 }, pb: { xs: 2, md: 0 } }} mt={-2} variant="h2">
          {subtitle}
        </Typography>
      )}
      {filters && (
        <Collapse in={showFilters} sx={{ my: -2, px: { xs: 2, md: 0 } }}>
          <Filters fields={filters} />
        </Collapse>
      )}
      {filteredData?.length ? (
        <>
          <MUITable data-testid="table" stickyHeader>
            <TableHead
              data-testid="table-head"
              sx={{ display: { xs: showHeadersMobile ? 'table-header-group' : 'none', md: 'table-header-group' } }}
            >
              <TableRow>
                {headers.length ? (
                  headers.map(({ label, align }) => (
                    <TableCell key={label} align={align}>
                      <Typography variant="body2" color="textSecondary">
                        {label}
                      </Typography>
                    </TableCell>
                  ))
                ) : (
                  <TableCell />
                )}
              </TableRow>
            </TableHead>
            <TableBody data-testid="table-body">
              {tableData.length ? (
                filteredData
                  .slice(tablePage * rows, tablePage * rows + rows)
                  .map(({ rowData, rowRedirect, rowAction, dropdownContent }, index) =>
                    dropdown ? (
                      <DropdownRow key={index} dropdownContent={dropdownContent}>
                        {rowData.slice(0, headers.length - 1).map(({ value, align }, index) => (
                          <TableCell key={index} align={align as TableCellProps['align']}>
                            {typeof value === 'object' ? (
                              value
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{ color: { xs: 'text.primary', md: COLORS.text.light3 } }}
                              >
                                {value}
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                      </DropdownRow>
                    ) : (
                      <TableRow
                        variant={flex ? 'mobileFlex' : ''}
                        key={index}
                        hover={clickableRows}
                        sx={{ cursor: clickableRows ? 'pointer' : 'default' }}
                        onClick={() => (rowAction ? rowAction() : rowRedirect ? router.push(rowRedirect) : undefined)}
                      >
                        {rowData.map(({ value, align }, index) => (
                          <TableCell key={index} align={align as TableCellProps['align']}>
                            <>
                              {headers[index]?.showLabelMobile && headers[index]?.label && (
                                <Typography
                                  variant="body3"
                                  color="textSecondary"
                                  sx={{ display: { xs: 'block', md: 'none' } }}
                                >
                                  {headers[index]?.label}:
                                </Typography>
                              )}
                              {typeof value === 'object' ? (
                                value
                              ) : (
                                <Typography
                                  variant="body2"
                                  sx={{ color: { xs: 'text.primary', md: COLORS.text.light3 } }}
                                >
                                  {value}
                                </Typography>
                              )}
                            </>
                          </TableCell>
                        ))}
                      </TableRow>
                    ),
                  )
              ) : (
                <TableRow />
              )}
            </TableBody>
          </MUITable>
          {!hidePagination && Math.ceil(filteredData.length / rows) > 1 && (
            <Pagination
              count={Math.ceil(filteredData.length / rows)}
              page={tablePage + 1}
              size="large"
              boundaryCount={3}
              shape="rounded"
              onChange={(e, page) => setTablePage(page - 1)}
            />
          )}
        </>
      ) : (
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
          gap={2}
          p={4}
          sx={{ width: '100%', flex: 1 }}
        >
          <Doodles type="end" />
          <Typography variant="body3">No se encontraron resultados</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const DropdownRow = ({ dropdownContent, children }: { dropdownContent?: JSX.Element; children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow variant="mobileFlex">
        {children}
        <TableCell>
          <IconButton
            sx={{ display: { xs: 'none', md: 'flex' } }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <MaterialIcon
              icon="keyboard_arrow_down"
              color="secondary"
              sx={{
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s ease',
              }}
            />
          </IconButton>
          <Stack width="100%" gap={1} sx={{ display: { xs: 'flex', md: 'none' }, pt: { xs: 1, md: 0 } }}>
            <Divider flexItem orientation="horizontal" />
            <Button
              aria-label="expand row button"
              fullWidth
              variant="text"
              sx={{ alignItems: 'center', lineHeight: 0 }}
              onClick={() => setOpen(!open)}
            >
              Ver {open ? 'menos' : 'más'}
              <MaterialIcon
                icon="keyboard_arrow_down"
                color="primary"
                sx={{
                  transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                  transition: '0.2s ease',
                }}
              />
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0, px: { xs: 2, md: 0 } }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid
              sx={{
                p: 2,
                mb: { xs: 2, md: 0 },
                borderRadius: { xs: '4px', md: 0 },
                backgroundColor: COLORS.background[0],
              }}
            >
              {dropdownContent}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export * from './filter';
