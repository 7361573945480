'use client';
import { IconButton, Paper, Tab, Tabs, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useRouter, useSelectedLayoutSegments } from 'next/navigation';
import { MaterialIcon } from '../../components';
import { MaterialIconType } from '../../components/materialIcon/types';

export type TabTableLayoutLink = {
  title: string;
  testid?: string;
  label: string;
  icon: MaterialIconType;
  action?: () => void;
};
export type TabTableLayoutActions = {
  onClick: () => void;
  label: string;
  icon: MaterialIconType;
};
type TabTableLayoutProps = {
  children: React.ReactNode;
  links?: TabTableLayoutLink[];
  actions?: TabTableLayoutActions[];
  activeSegment?: string;
};

export const TabTableLayout = ({ children, links, actions, activeSegment }: TabTableLayoutProps) => {
  const segment = useSelectedLayoutSegments();
  const router = useRouter();

  return (
    <Paper sx={{ width: '100%', flex: 1 }} data-testid="tabtable-layout">
      <Grid
        container
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs value={links?.findIndex((link) => link.title === (activeSegment || segment[0]))}>
          {links?.map(({ title, label, icon, testid, action }) => (
            <Tab
              data-testid={testid}
              iconPosition="start"
              icon={<MaterialIcon icon={icon} />}
              key={title}
              label={label}
              onClick={() => (action ? action() : router.push(title))}
            />
          ))}
        </Tabs>
        <Grid container gap={1} sx={{ px: 1 }}>
          {actions?.map(({ onClick, label, icon }) => (
            <Tooltip key={label} title={label}>
              <IconButton onClick={onClick}>
                <MaterialIcon icon={icon} />
              </IconButton>
            </Tooltip>
          ))}
        </Grid>
        {/* <IconButton onClick={() => router.back()}>
            <MaterialIcon icon="download" />
          </IconButton> */}
      </Grid>
      {children}
    </Paper>
  );
};
