'use client';
import { Box, Link, Stack, Typography } from '@mui/material';
import { COLORS, MaterialIcon } from '@repo/ui';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Carrousel component displays a horizontal scrolling list of items with optional scroll buttons.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {any[]} props.items - Array of items to be displayed in the carousel.
 * @param {string} props.title - Title of the carousel.
 * @param {string} props.seeMoreLink - URL for the "See More" link.
 * @param {React.ReactNode} props.children - The child nodes to be rendered inside the carousel.
 *
 * @returns {JSX.Element} The rendered Carrousel component.
 *
 * @example
 * <Carrousel items={items} title="Top Rated" seeMoreLink="/see-more">
 *   {items.map(item => (
 *     <Card key={item.id} item={item} />
 *   ))}
 * </Carrousel>
 */

interface CarouselProps {
  title: string;
  seeMoreLink: string;
  children: React.ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ title, seeMoreLink, children }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState(0);
  const totalChildren = React.Children.count(children);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(totalChildren > 2);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Scroll to the left
  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' });
    }
  };

  // Scroll to the right
  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      const newIndex = Math.round(scrollLeft / cardWidth);
      setCurrentIndex(newIndex);
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const calculateCardWidth = () => {
    if (carouselRef.current && carouselRef.current.firstChild) {
      const firstCard = carouselRef.current.firstChild as HTMLElement;
      setCardWidth(firstCard.clientWidth + 32);
    }
  };

  useEffect(() => {
    // Set card width and recalculate on resize
    calculateCardWidth();
    window.addEventListener('resize', calculateCardWidth);

    // Add scroll event listener to update button visibility and current index
    if (carouselRef.current) {
      carouselRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('resize', calculateCardWidth);
      if (carouselRef.current) {
        carouselRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [children, cardWidth]);

  return (
    <Stack sx={{ position: 'relative' }} gap={1} data-testid="carrousel" mt={1}>
      <Box display={{ xs: 'none', md: 'flex' }} justifyContent="space-between">
        <Typography variant="h1">{title}</Typography>
        <Link href={seeMoreLink}>
          <Typography variant="body1" color={COLORS.primary.purple.main}>
            Ver más
          </Typography>
        </Link>
      </Box>

      {/* Scroll buttons */}
      <>
        {canScrollLeft && (
          <Box
            sx={{
              position: 'absolute',
              left: 15,
              top: '50%',
              zIndex: 1,
              backgroundColor: '#667085',
              cursor: 'pointer',
            }}
            width="34px"
            height="34px"
            borderRadius="50%"
            onClick={scrollLeft}
            display={{ xs: 'none', md: 'flex' }}
            justifyContent="center"
            alignItems="center"
            color="white"
          >
            <MaterialIcon icon="chevron_left" size={20} />
          </Box>
        )}

        {canScrollRight && (
          <Box
            sx={{
              position: 'absolute',
              right: 15,
              top: '50%',
              zIndex: 1,
              backgroundColor: '#667085',
              cursor: 'pointer',
            }}
            width="34px"
            height="34px"
            borderRadius="50%"
            onClick={scrollRight}
            display={{ xs: 'none', md: 'flex' }}
            justifyContent="center"
            alignItems="center"
            color="white"
          >
            <MaterialIcon icon="chevron_right" size={20} />
          </Box>
        )}
      </>

      {/* Scrollable container */}
      <Box
        ref={carouselRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': { display: 'none' },
          scrollSnapType: 'x mandatory',
        }}
        borderRadius={2}
        onScroll={handleScroll}
        gap={2}
        p={{ xs: 2, md: 0 }}
      >
        {children}
      </Box>

      {/* Mobile Index Indicator */}
      <Stack direction="row" justifyContent="center" mt={1} spacing={1} display={{ xs: 'flex', md: 'none' }}>
        {Array.from({ length: totalChildren }).map((_, index) => (
          <Box
            key={index}
            sx={{
              width: 20,
              height: 10,
              borderRadius: 2,
              backgroundColor: index === currentIndex ? COLORS.primary.purple.main : '#C4C4C4',
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
