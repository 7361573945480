'use client';
import {
  Stepper as MUIStepper,
  Step,
  StepLabel,
  StepLabelProps,
  StepLabelSlotsAndSlotProps,
  StepperProps,
} from '@mui/material';
import { useEffect, useState } from 'react';

export type stepData = { label: string; info?: string };
export type activeOption = { name: string; value: number };

type StepperData = {
  stepsData: stepData[];
  activeKey?: string;
  activeOptions?: activeOption[];
  lastAlwaysActive?: boolean;
};

/**
 * Stepper component with custom data to render.
 *
 * @todo - Style stepper according to figma design.
 *
 * @param {StepperData} data - The data to render on the Stepper component.
 * @param {StepLabelProps & StepLabelSlotsAndSlotProps} labelProps - The props received by the StepLabel component.
 * @return {JSX.Element} The rendered Stepper component.
 */
export const Stepper = ({
  data,
  labelProps,
  ...rest
}: {
  data: StepperData;
  labelProps?: StepLabelProps & StepLabelSlotsAndSlotProps;
} & StepperProps) => {
  const { stepsData, activeKey, activeOptions, lastAlwaysActive } = data;
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (activeKey && activeOptions) {
      activeOptions.forEach((option) => {
        if (option.name === activeKey) {
          setActiveStep(option.value);
        }
      });
    }
  }, [activeKey, activeOptions]);

  return (
    <MUIStepper
      data-testid="stepper"
      activeStep={lastAlwaysActive && stepsData.length ? stepsData.length - 1 : activeStep}
      orientation="vertical"
      {...rest}
    >
      {stepsData.length &&
        stepsData.map((step) => (
          <Step key={step.label}>
            <StepLabel optional={step?.info} {...labelProps}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
    </MUIStepper>
  );
};
