'use client';

import { Box, BoxProps, Button, ButtonProps, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { FormEvent } from 'react';
import { Transition } from '../transition';

/**
 * Renders a generic form component with a button and no submit logic.
 *
 * @param {Object} formProps - The props for the Form component.
 * @param {Object} formProps.sx - The custom styles for the Form component.
 * @param {ReactNode} formProps.children - The children of the Form component.
 * @param {Function} formProps.onSubmit - The submit handler for the form.
 * @param {Object} [buttonProps][] - The props for the Button components.
 * @param {string} [buttonProps.children='continuar'] - The label for the Button component.
 * @return {JSX.Element} The rendered Form component.
 */
export const Form = ({
  submitting,
  formProps: { sx, children: formChildren, onSubmit, ...formProps },
  buttonProps = [{ children: 'continuar' }],
}: {
  submitting?: boolean;
  formProps: BoxProps;
  buttonProps?: ButtonProps[];
}) => {
  const formHasError = (formChildren: React.ReactNode): any => {
    let result: any = React.Children.map(formChildren, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.children && !child.props.error) {
          return formHasError(child.props.children);
        }
        // This blur function highlight errors on form fields
        child.props?.onBlur?.();
        // Conditional that prevents the form submit if a required field is empty
        if (child?.props?.required && child?.props?.value === '') {
          return true;
        }
        // Conditional that prevents the form submit if a field has a visual error or an internal error
        if (child.props.error || child.props['data-internalerror']) {
          return true;
        }
      }
    });
    if (result?.[0] === true) {
      return true;
    }
  };

  return (
    <form
      noValidate
      data-testid="form"
      onSubmit={(e: FormEvent<any>) => {
        e.preventDefault();
        if (!formHasError(formChildren)) {
          onSubmit?.(e);
        }
      }}
      style={{ height: '100%', width: '100%' }}
    >
      <Box
        {...formProps}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
          paddingBottom: { xs: 4, md: 0 },
          gap: 2,
          pointerEvents: submitting ? 'none' : 'auto',
          filter: submitting ? 'grayscale(100%)' : 'none',
          ...sx,
        }}
      >
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>{formChildren}</Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
          {buttonProps.map(({ children, name, disabled, ...rest }, index) => (
            <Transition key={index} type="fadeUp" delay={index * 0.2}>
              <Button
                fullWidth
                data-testid={`button-${name}`}
                disabled={submitting || disabled}
                type={index === 0 ? 'submit' : 'button'}
                variant={index === 0 ? 'contained' : 'inverted'}
                children={
                  index === 0 && submitting ? (
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress size={20} />
                      Cargando...
                    </Box>
                  ) : (
                    children
                  )
                }
                {...rest}
              />
            </Transition>
          ))}
        </Grid>
      </Box>
    </form>
  );
};
