import { Box, Container, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Logo, Transition } from '@repo/ui';

/**
 * Renders children in a login layout.
 *
 * @param {React.ReactNode} children - The content to be rendered within the layout.
 * @return {JSX.Element} The layout of the login.
 */
export const LoginLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <Grid
      component={'main'}
      data-testid="login-layout"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100dvh',
        background: {
          // xs: 'transparent',
          xs: `linear-gradient( rgba(243, 236, 255, 0.9), rgba(243, 236, 255, 0.9)), url(${process.env.NEXT_PUBLIC_CDN_ROUTE}assets/images/background_doodles.svg)`,
        },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 4,
          background: {
            // xs: '#fff',
            xs: 'transparent',
          },
          py: { xs: 0, md: 8 },
        }}
      >
        <Transition type="fadeUp">
          <Box
            data-testid="safeSpace"
            component={'section'}
            sx={{
              width: { xs: '100vw', md: '612px' },
              height: '100%',
              minHeight: { xs: '100vh', md: '630px' },
              backgroundColor: 'background.paper',
              padding: { xs: 2, md: 12 },
              paddingTop: { xs: 4 },
              borderRadius: 6,
            }}
          >
            <Stack gap={4} sx={{ height: '100%' }}>
              <Stack gap={2} width="100%" textAlign="center">
                <Logo height={35} style={{ flex: 'none' }} />
                <Typography variant="body2" color="primary">
                  Una nueva manera de llegar a lo que querés
                </Typography>
              </Stack>
              {children}
            </Stack>
          </Box>
        </Transition>
      </Container>
    </Grid>
  );
};
