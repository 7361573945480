'use client';
import { FormLabel, Slider as MUISlider, SliderProps as MUISliderProps, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useEffect, useState } from 'react';

type SliderProps = MUISliderProps & {
  label?: string;
  required?: boolean;
  value?: number;
  onChange?: (_e: any) => void;
  onRawChange?: (_value: number | number[]) => void;
  labelWrapper?: (_label: string) => string;
};

/**
 * Renders a custom radio group component with optional required, label, helperText and error features with children.
 *
 * @param {string} label - The label text for the slider.
 * @param {boolean} required - Applies an asterisk to the label text.
 * @param {value} value - The value of the slider.
 * @param {onChange} onChange - The function to be called when the slider value debounces.
 * @param {onRawChange} onRawChange - The function to be called when the slider value changes.
 * @param {labelWrapper} labelWrapper - Wrapper function for the label.
 */
export const Slider = ({
  label,
  required,
  value,
  onChange,
  onRawChange,
  labelWrapper,
  defaultValue,
  ...rest
}: SliderProps) => {
  const [rawValue, setRawValue] = useState<number | number[]>(Number(value) || defaultValue || 0);
  useEffect(() => {
    onRawChange?.(rawValue);
  }, [rawValue]);
  return (
    <Grid container direction={'column'} data-testid="slider-container">
      <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <FormLabel required={required} id="slider-label" sx={{ textWrap: 'wrap', maxWidth: '150px', mb: 0 }}>
          {label}
        </FormLabel>
        <Typography variant="h1" color={'primary.main'} data-testid="slider-value">
          {labelWrapper?.(`${rawValue}`) || `${rawValue}`}
        </Typography>
      </Grid>
      <MUISlider
        data-testid="slider"
        // color="secondary"
        defaultValue={value}
        aria-label="slider-label"
        valueLabelDisplay="off"
        {...rest}
        sx={{ ...rest.sx, my: 1, display: rest.min && rest.min === rest.max ? 'none' : 'block' }}
        value={rawValue}
        onChange={(_e, _value) => {
          setRawValue(_value);
        }}
        onChangeCommitted={(e) => onChange?.({ ...e, target: { ...e.target, value: rawValue } })}
      />
    </Grid>
  );
};
