import { IconButton } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import React from 'react';
import { MaterialIcon } from '../materialIcon';

/**
 * Renders a Snackbar component with Alert and close button.
 *
 * @todo Receive subtext param
 *
 * @param {boolean} open - The boolean for the Snackbar open prop.
 * @param {AlertProps['severity']} kind - The kind of message for the Snackbar icon & color.
 * @param {string} text - The text to show on the Snackbar.
 * @param {Function} close - The function for closing the snackbar.
 * @return {JSX.Element} The rendered Modal component.
 */
export const Toast = ({
  open,
  kind,
  text,
  close,
  ...rest
}: {
  open: boolean;
  kind?: AlertProps['severity'];
  text: string;
  close?: () => void;
} & SnackbarProps) => {
  const handleSnackbarClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    close?.();
  };

  const SnackbarAction = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <MaterialIcon icon="close" size={20} />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
      {...rest}
    >
      <Alert severity={kind} action={SnackbarAction} variant="filled">
        {text}
      </Alert>
    </Snackbar>
  );
};
