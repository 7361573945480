import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  RadioGroupProps,
  TextFieldProps,
} from '@mui/material';

type optionsModel = {
  value: string | number;
  label: string | JSX.Element;
};

/**
 * Renders a custom radio group component with optional required, label, helperText and error features with children.
 *
 * @param {string} label - The label text for the radio group.
 * @param {boolean} required - Applies an asterisk to the label text.
 * @param {string} errorText - The text for the error helperText.
 * @param {optionsModel[]} options - The options to display as radio buttons with values & labels.
 * @return {JSX.Element} The rendered RadioGroup component.
 */
export const RadioGroup = ({
  label,
  required,
  options,
  error,
  helperText,
  ...rest
}: {
  label?: string;
  required?: boolean;
  options?: optionsModel[];
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
} & RadioGroupProps) => {
  return (
    <MuiRadioGroup
      data-testid="radio-group"
      sx={{
        '& >label:nth-last-of-type(1)': {
          margin: '0px',
        },
      }}
      {...rest}
    >
      <FormLabel required={required && !!label} error={required && error}>
        {label}
      </FormLabel>
      {options?.map((o) => (
        <FormControlLabel
          sx={{ '& div': { width: '100%' } }}
          key={o.value}
          variant={error ? 'error' : ''}
          required={required}
          value={o.value}
          control={<Radio data-testid={`radio-${o.value}`} />}
          label={o.label}
        />
      ))}
      <FormHelperText sx={{ margin: '3px 14px 0px' }} error>
        {error && helperText}
      </FormHelperText>
    </MuiRadioGroup>
  );
};
