export const COLORS = {
  background: ['#F3ECFF', '#E9DCFF'],
  primary: {
    purple: { main: '#863DFF', light: '#CEB0FF' },
    blue: { main: '#0039A6', light: '#37B8D7' },
    green: { main: '#03FF00', light: '#D0E72F', dark: '#029500' },
  },
  text: {
    main: '#222222',
    light: '#535353',
    light2: '#A7A7A8',
    light3: '#667085',
    light4: '#E1E0E1',
    placeholder: '#A7A7A8', //note: figma has #667085
    inverted: '#ffffff',
    label: '#344054',
  },
  state: {
    error: '#D70E00',
    disabled: '#999999',
  },
};
