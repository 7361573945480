'use client';
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { COLORS, Logo, MaterialIcon, MenuButton, MenuButtonModel } from '@repo/ui';
import Link from 'next/link';
import { useSelectedLayoutSegments } from 'next/navigation';
import { MaterialIconType } from '../materialIcon/types';

export type MenuModel = {
  buttonList: MenuButtonModel[];
  headerTitle: string;
  headerButton?: { action: any; text: string; icon?: MaterialIconType };
  userData?: string[];
  closeSession?: () => void;
  closeMenu?: () => void;
};

/**
 * Renders the commerce navigation menu component.
 * @param {MenuModel} props - The component properties.
 *
 * @return {JSX.Element} The rendered Menu component.
 */

export const Menu = ({ buttonList, headerTitle, headerButton, userData, closeSession, closeMenu }: MenuModel) => {
  // Filters Next.js sections to access real segments
  const segment = useSelectedLayoutSegments().filter((str) => !/\(.*?\)/.test(str));
  return (
    <Stack data-testid="menu" sx={{ height: '100%', py: { xs: 2, lg: 4 }, px: 2 }} spacing={4}>
      {closeMenu && (
        <Grid container justifyContent={'flex-end'} sx={{ display: { xs: 'flex', lg: 'none' } }}>
          <IconButton data-testid="menu-close" onClick={closeMenu}>
            <MaterialIcon icon="close" />
          </IconButton>
        </Grid>
      )}

      <Grid
        width="100%"
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'column'}
        gap={'12px'}
        py={4}
        pt={{ xs: 0, lg: 4 }}
      >
        <Box height={25}>
          <Logo height="100%" />
        </Box>

        <Typography variant="h2" color={COLORS.primary.blue.main}>
          {headerTitle}
        </Typography>
      </Grid>

      {headerButton && (
        <Button onClick={headerButton.action} sx={{ gap: 0.5 }}>
          {headerButton.icon && <MaterialIcon icon={headerButton.icon} />}
          {headerButton.text}
        </Button>
      )}
      <Divider />
      <Stack gap={2} sx={{ flex: 1 }}>
        {buttonList?.map((button, ix) => (
          <Link key={ix} href={`/${button.title}`}>
            <MenuButton icon={button.icon} text={button.text} active={segment[0] === button.title} sx={button.sx} />
          </Link>
        ))}
      </Stack>
      <Divider />
      <Stack gap={2} justifyContent="center">
        <MenuButton icon="download" text="Cerrar sesión" onClick={closeSession} sx={{ color: 'red' }} />
        {userData && (
          <Stack gap={1} paddingLeft={'16px'}>
            {userData?.map((data, ix) => (
              <Typography key={ix} variant="caption" color={COLORS.text.placeholder}>
                {data}
              </Typography>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
