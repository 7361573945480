'use client';
import { cubicBezier, motion, type AnimationProps } from 'framer-motion';

type transitionTypes =
  | 'fade'
  | 'fadeUp'
  | 'fadeDown'
  | 'fadeLeft'
  | 'fadeRight'
  | 'slideUp'
  | 'slideDown'
  | 'slideLeft'
  | 'slideRight'
  | 'disabled';

/**
 * Renders a transition div that animates its children when rendered.
 *
 * @param {React.ReactNode} children - The content to be animated when rendered.
 * @param {transitionTypes} type - The animation type @default 'fade'
 * @param {Number} duration - Custom animation duration time override.
 * @param {Object} style - Styles override for the div component.
 * @return {JSX.Element} The rendered transition div with its children.
 */
export const Transition = ({
  children,
  type = 'fade',
  mobileType,
  duration = 0.3,
  delay = 0,
  style,
}: {
  children: React.ReactNode;
  type?: transitionTypes;
  mobileType?: transitionTypes;
  duration?: number;
  delay?: number;
  style?: {};
}) => {
  const isClient = typeof window !== 'undefined';
  const isMobile = isClient && window.innerWidth < 900;
  const transitions: {
    // eslint-disable-next-line no-unused-vars
    [T in transitionTypes]: AnimationProps;
  } = {
    fade: {
      initial: { opacity: 0 },
      exit: { opacity: 0 },
      animate: { opacity: 1 },
    },
    fadeUp: {
      initial: { opacity: 0, y: '10%' },
      exit: { opacity: 0, y: '10%' },
      animate: { opacity: 1, y: '0%' },
    },
    fadeDown: {
      initial: { opacity: 0, y: '-10%' },
      exit: { opacity: 0, y: '-10%' },
      animate: { opacity: 1, y: '0%' },
    },
    fadeLeft: {
      initial: { opacity: 0, x: '-10%' },
      exit: { opacity: 0, x: '-10%' },
      animate: { opacity: 1, x: '0%' },
    },
    fadeRight: {
      initial: { opacity: 0, x: '10%' },
      exit: { opacity: 0, x: '10%' },
      animate: { opacity: 1, x: '0%' },
    },
    slideUp: {
      initial: { y: '10%' },
      exit: { y: '10%' },
      animate: { y: '0%' },
    },
    slideDown: {
      initial: { y: '-10%' },
      exit: { y: '-10%' },
      animate: { y: '0%' },
    },
    slideLeft: {
      initial: { x: '-10%' },
      exit: { x: '-10%' },
      animate: { x: '0%' },
    },
    slideRight: {
      initial: { x: '10%' },
      exit: { x: '10%' },
      animate: { x: '0%' },
    },
    disabled: { initial: false, exit: undefined, animate: false },
  };
  /* c8 ignore start */
  const transition = transitions[!isClient && mobileType ? 'disabled' : isMobile && mobileType ? mobileType : type];
  /* c8 ignore end */

  return (
    <motion.div
      initial={transition.initial}
      exit={transition.exit}
      animate={transition.animate}
      transition={{ ease: cubicBezier(0.4, 0.0, 0.2, 1), duration, delay }}
      style={style}
    >
      {children}
    </motion.div>
  );
};
