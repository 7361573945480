'use client';

import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography } from '@mui/material';
import { apiResponse } from '@repo/api';
import { formatter } from '@repo/utils';
import { useState } from 'react';
import { MaterialIcon } from '../../components';

type LoginProps<T> = {
  credentialType?: 'username' | 'documentNumber';
  connector?: (_data: { username: string; password: string }) => Promise<apiResponse<T>>;
  callback?: (_connectorResponse: T) => void;
  onError?: () => void;
  forgotPassword?: () => void;
  extraButtons?: {
    label: string;
    onClick: () => void;
  }[];
};

/**
 * A reusable login component that handles user authentication.
 * It also features validation for all fields and a loading state.
 *
 * @param {Object} connector - A function that connects to an API to authenticate the user.
 * @param {Function} callback - An optional callback function to handle the API response.
 * @param {'email' | 'documentNumber'} credentialType - The type of credential to use in the login form @default email.
 * @param {Object} extraButtons - An optional array of extra buttons to display in the login form.
 * @return {JSX.Element} A JSX element representing the login form.
 */
export const Login = <T,>({
  connector,
  callback,
  credentialType = 'documentNumber',
  extraButtons,
  forgotPassword,
  onError,
}: LoginProps<T>) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<{ username?: string; password?: string }>({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Validation functions for different fields
  const validateDocument = (doc: string) => /^\d{7,10}$/.test(doc);
  const validatePassword = (password: string) =>
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-]).{8,}$/.test(password);

  // Function that validates each field on blur
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value === '') return;
    let error = '';

    if (name === 'username') {
      if (credentialType === 'documentNumber' && !validateDocument(value.replace(/\D/g, ''))) {
        error = 'Ingresa un DNI válido';
      }
    } else if (name === 'password' && !validatePassword(value)) {
      error = 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un símbolo y un número';
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    connector?.({
      username: username,
      password: password,
    })
      .then((connectorResponse) => {
        if (connectorResponse.softError) {
          setLoading(false);
          setFormErrors({
            username: 'Usuario y/o contraseña incorrectos',
            password: 'Usuario y/o contraseña incorrectos',
          });
        } else {
          callback?.(connectorResponse);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        onError?.();
      });
  };

  const resetErrors = () => setFormErrors({ username: '', password: '' });

  return (
    <Box component="form" onSubmit={handleSubmit} data-testid="login-form">
      <Stack gap={2}>
        <Typography variant="h1">Iniciar sesión</Typography>

        {credentialType === 'username' ? (
          <TextField
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            label="Nombre de usuario"
            name="username"
            autoFocus
            placeholder="Ej: juan.perez"
            onBlur={handleBlur}
            onFocus={resetErrors}
            error={!!formErrors.username}
            helperText={formErrors.username}
            slotProps={{ htmlInput: { inputMode: 'string', autoComplete: 'username', 'data-testid': 'username' } }}
          />
        ) : (
          <TextField
            onChange={(e) => setUsername(e.target.value.replace(/\D/g, ''))}
            value={formatter(username, 'dni')}
            label="DNI"
            name="username"
            autoFocus
            placeholder="Ej: 12345678"
            onBlur={handleBlur}
            onFocus={resetErrors}
            error={!!formErrors.username}
            helperText={formErrors.username}
            slotProps={{ htmlInput: { maxLength: 10, inputMode: 'numeric', 'data-testid': 'documentNumber' } }}
          />
        )}

        <TextField
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          label="Contraseña"
          name="password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Contraseña"
          onBlur={handleBlur}
          onFocus={resetErrors}
          error={!!formErrors.password}
          helperText={formErrors.password}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)} data-testid="show-password">
                  {showPassword ? <MaterialIcon icon="visibility_off" /> : <MaterialIcon icon="visibility" />}
                </IconButton>
              ),
            },
            htmlInput: { autoComplete: 'current-password', 'data-testid': 'password' },
          }}
        />

        <Typography
          variant="body2"
          color="primary.main"
          sx={{ cursor: 'pointer' }}
          onClick={() => (forgotPassword ? forgotPassword() : null)}
        >
          Olvidé mi contraseña
        </Typography>

        {extraButtons?.map((button) => (
          <Button key={button.label} onClick={button.onClick} fullWidth variant="inverted">
            {button.label}
          </Button>
        ))}

        <Button type="submit" disabled={loading} fullWidth variant="contained" data-testid="login" sx={{ mt: 2 }}>
          {loading ? (
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress size={20} />
              Cargando...
            </Box>
          ) : (
            'Ingresar'
          )}
        </Button>
      </Stack>
    </Box>
  );
};
