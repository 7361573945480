/**
 * Parses a JSON Web Token (JWT) and returns its decoded payload.
 *
 * @param {string} token - The JWT to be parsed.
 */
export const parseJwt = (token: string) => {
  try {
    if (typeof window !== 'undefined') {
      const base64Url = token.split('.')[1] || '';
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } else {
      return JSON.parse(Buffer.from(token.split('.')[1] || '', 'base64').toString());
    }
  } catch (e) {
    return null;
  }
};
