import { Button, ButtonProps, IconProps } from '@mui/material';
import { COLORS, MaterialIcon } from '@repo/ui';
import { MaterialIconType } from '../materialIcon/types';

export type MenuButtonModel = {
  icon?: MaterialIconType;
  text: string;
  active?: boolean;
  iconStyles?: IconProps['sx'];
  title?: string;
  sx?: ButtonProps['sx'];
};

export const MenuButton = ({ icon, text, active, iconStyles, sx, ...rest }: MenuButtonModel & ButtonProps) => {
  return (
    <Button
      data-testid="menu-button"
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '5px',
        color: COLORS.primary.blue.main,
        width: '100%',
        border: '1px solid',
        borderRadius: '8px',
        borderColor: active ? COLORS.primary.purple.main : 'transparent',
        backgroundColor: active ? COLORS.background[0] : 'transparent',
        ...sx,
      }}
      {...rest}
    >
      {icon && <MaterialIcon icon={icon} sx={{ ...iconStyles }} />}
      {text}
    </Button>
  );
};
