'use client';
import { Box, Typography } from '@mui/material';
import { MaterialIcon } from '@repo/ui';
import { useRouter } from 'next/navigation';

/**
 * Back arrow icon button that triggers prop function or navigates to previous page when clicked using next navigation router.
 *
 * @param {Function} action - The custom function to trigger.
 * @return {JSX.Element} The rendered back arrow icon button.
 */
export const BackArrow = ({ action, label }: { action?: Function; label?: string }) => {
  const router = useRouter();
  return (
    <Box
      display="flex"
      data-testid="backarrow"
      alignItems="center"
      sx={{ cursor: 'pointer', width: 'fit-content' }}
      gap={1}
      onClick={() => (action ? action() : router.back())}
    >
      <MaterialIcon icon="arrow_back" color="primary" onClick={() => (action ? action() : router.back())} />
      {label && (
        <Typography variant="body1" color="disabled">
          {label}
        </Typography>
      )}
    </Box>
  );
};
