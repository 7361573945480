import { Box, Divider, Stack, Typography } from '@mui/material';
import { MaterialIcon, MaterialIconType } from '../../components';

export type DividedLayoutTypes = {
  title?: {
    icon?: MaterialIconType;
    text: string;
  };
  menu: JSX.Element;
  content: JSX.Element;
};

/**
 * Divided content layout with optional title and icon.
 *
 * @param {DividedLayoutTypes} props - The props to render the DividedLayout features when passed.
 * @return {JSX.Element} The rendered DividedLayout component.
 */
export const DividedLayout = ({ props }: { props: DividedLayoutTypes }): React.ReactNode => {
  const { title, menu, content } = props;
  return (
    <Box
      display="flex"
      height={{ xs: 'unset', md: '100%' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      gap={{ xs: 4, md: 0 }}
    >
      <Stack
        data-testid="divided-layout"
        sx={{
          p: { xs: 0, md: 4 },
          flex: 0.35,
          minWidth: { xs: 'unset', md: '300px' },
        }}
      >
        {title && (
          <Stack direction="row" gap={1} alignItems="center">
            {title.icon && <MaterialIcon icon={title?.icon} size={25} color="secondary" />}
            <Typography variant="h1" fontSize={{ xs: '14px', md: '20px' }}>
              {title?.text}
            </Typography>
          </Stack>
        )}
        {menu}
      </Stack>
      <Divider flexItem orientation="vertical" sx={{ display: { xs: 'none', lg: 'flex' } }} />
      <Stack sx={{ p: { xs: 0, md: 4 }, flex: 1 }}>{content}</Stack>
    </Box>
  );
};
