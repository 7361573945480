'use client';

import { Typography, TypographyProps } from '@mui/material';
import Grid from '@mui/material/Grid2';

/**
 * Renders a title component with the provided children wrapped in a Material-UI Grid and Typography components.
 *
 * @param {TypographyProps} props - The props object containing the children and any other TypographyProps.
 * @return {JSX.Element} The rendered title component.
 */
export const Title = ({ children, ...rest }: TypographyProps) => {
  return (
    <Grid container>
      <Typography
        data-testid="title"
        variant="h1"
        color="secondary.main"
        sx={{ textTransform: 'none', height: 44, ...rest.sx }}
        {...rest}
      >
        {children}
      </Typography>
    </Grid>
  );
};
