'use client';

import { SpeedDial, SpeedDialAction } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { MaterialIcon } from '../materialIcon';
import { MaterialIconType } from '../materialIcon/types';

export type DevToolsAction = {
  icon: MaterialIconType;
  name: string;
  onClick: () => void;
  onLongClick?: () => void;
  reset?: () => void;
  active?: boolean;
};

/**
 * Renders a FAB component with a mock enable/disable logic and custom actions.
 *
 * @param {DevToolsAction[]} customActions - Array of action buttons with custom logic to be rendered.
 * @return {JSX.Element} The rendered DevTools component.
 */
export const DevTools = ({ customActions }: { customActions?: DevToolsAction[] }): JSX.Element => {
  const resetSettings = () => {
    customActions?.forEach((action) => {
      action.reset?.();
    });
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const baseActions: DevToolsAction[] = [
    {
      icon: 'reset_settings',
      name: 'Reset Settings',
      onClick: resetSettings,
    },
  ];

  const actions = useMemo(() => [...baseActions, ...(customActions ?? [])], [customActions]);
  const pressTimer = useRef<number>();
  return process.env.NEXT_PUBLIC_ENABLE_DEVTOOLS ? (
    <SpeedDial
      onClick={handleOpen}
      open={open}
      aria-expanded={open}
      data-testid="dev-tools"
      ariaLabel="DevTools"
      sx={{ position: 'fixed', bottom: 16, right: 16 }}
      icon={<MaterialIcon icon="settings" sx={{ color: '#fff' }} />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          FabProps={{
            sx: action.active
              ? {
                  color: '#fff',
                  bgcolor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }
              : undefined,
          }}
          tooltipOpen
          tooltipTitle={action.name}
          key={action.name}
          icon={<MaterialIcon icon={action.icon} />}
          onMouseDown={(e) => {
            e.stopPropagation();
            pressTimer.current = window.setTimeout(() => action.onLongClick?.(), 1000);
            return false;
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
            clearTimeout(pressTimer.current);
            return false;
          }}
          onClick={(e) => {
            action.onClick?.();
            e.stopPropagation();
          }}
        />
      ))}
    </SpeedDial> /* c8 ignore start */
  ) : (
    <></>
  );
  /* c8 ignore end */
};
