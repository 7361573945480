import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { COLORS } from '../../constants';

/**
 * Renders a custom select component with a custom label and placeholder, extends MUI SelectProps.
 *
 * @param {string} label - The label of the Dropdown component.
 * @param {string} placeholder - The placeholder of the Dropdown component.
 * @param {string} errorText - The helper text to show when an error exists.
 * @param {Array<Object>} options - The options for the Dropdown component.
 * @return {JSX.Element} The rendered Dropdown component.
 */
export const Dropdown = ({
  label,
  placeholder,
  options,
  helperText,
  ...selectProps
}: {
  label: string;
  placeholder?: string;
  helperText?: string;
  options: { label: string; value: string }[];
} & SelectProps) => {
  const { sx, value, required, error, ...rest } = selectProps;
  return (
    <FormControl error={error} fullWidth>
      <InputLabel id="onb-select" required={required}>
        {label}
      </InputLabel>
      <Select
        labelId="onb-select"
        displayEmpty
        value={value}
        required={required}
        error={error}
        sx={value === '' ? { color: COLORS.text.placeholder, ...sx } : sx}
        {...rest}
      >
        {placeholder && (
          <MenuItem value="" disabled sx={{ display: 'none' }}>
            {placeholder}
          </MenuItem>
        )}
        {options.map(({ label: _label, value: _value }) => (
          <MenuItem key={_label} value={_value}>
            {rest.multiple ? (
              <>
                <Checkbox checked={(value as string[]).includes(_value)} />
                <ListItemText primary={_label} />
              </>
            ) : (
              _label
            )}
          </MenuItem>
        ))}
      </Select>
      {required && helperText && error && (
        <FormHelperText sx={{ margin: '3px 14px 0px' }} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
