'use client';

/**
 * Retrieves the value of a client-side cookie by its name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @return {string|undefined} The value of the cookie if it exists, otherwise undefined.
 */
export const getClientCookie = (name: string) => {
  if (typeof document !== 'undefined') {
    const cookie = document.cookie.split(';').find((c) => c.trim().startsWith(`${name}=`));
    if (!cookie) return undefined;
    return cookie.split('=')[1];
  }
};

/**
 * Sets a client-side cookie with the given name and value.
 *
 * @param {string} name - The name of the cookie to set.
 * @param {string} value - The value of the cookie to set.
 * @return {void}
 */
export const setClientCookie = (name: string, value: string, expires?: string) => {
  if (typeof document !== 'undefined' && value)
    document.cookie = `${name}=${value};${expires ? `expires=${expires};` : ''}path=/`;
};

/**
 * Deletes a client cookie.
 *
 * @param {string} name - The name of the cookie to delete.
 * @return {void}
 */
export const deleteClientCookie = (name: string) => {
  if (typeof document !== 'undefined') document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};
