import { ChipProps, Chip as MUIChip } from '@mui/material';

export type ChipOption = { name: string; color: ChipProps['color'] };
export type ChipOptions = ChipOption[];

/**
 * Renders a custom chip component.
 *
 * @param {string} value - The label text for the chip.
 * @param {ChipOption[]} options - Array of options to handle the chip color based on the provided value.
 * @return {JSX.Element} The rendered custom chip component.
 */
export const Chip = ({
  value,
  options,
  ...rest
}: {
  value: string;
  options: ChipOptions;
} & ChipProps) => {
  return (
    <MUIChip label={value} color={options.find(({ name }) => name === value)?.color} {...rest} data-testid="chip" />
  );
};
